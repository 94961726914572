/* eslint-disable */
import React, { useEffect, useState, useCallback, ReactElement } from "react"
import {
  useTheme,
  Box,
  Link,
  Paper,
  Dialog,
  Typography,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import "./GovernanceInfo.scss"
import GovInfoIcon from "./../assets/icons/gov_info_icon.png"
import SRS_ICON from "./../assets/icons/srs.png"
import VESRS_ICON from "./../assets/icons/vesrs.svg"
import Button from "../components/Button"
import { useVeTokenContract, useTokenContract, useFeeDistributorContract } from "../hooks/useContract"
import ConfirmTransaction from "../components/ConfirmTransaction"
import { Web3Provider } from "@ethersproject/providers"
import { useActiveWeb3React } from "../hooks"
import { BigNumber } from "ethers"
import { shortenAddress } from "../utils/shortenAddress"
import { getEtherscanLink } from "../utils/getEtherscanLink"
import { formatBNToString, commify, getGraph } from "../utils"
import { SRS_TOKEN } from "../constants"
import moment from "moment"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { useGasPrice } from "./../hooks/useGas"
import { useTranslation } from "react-i18next"

interface Response {
  data: {
    lockSystemInfos: [
      {
        averageLockTime: string
      },
    ]
  }
}

interface Props {
  myLockedSrs: any
  MyVeSRS: any
  TotalLockSRS: any
  unlockTime: any
  veTokenMinted: any
}

const GovernanceInfo = ({
  myLockedSrs,
  MyVeSRS,
  TotalLockSRS,
  unlockTime,
  veTokenMinted,
}: Props): ReactElement => {
  const theme = useTheme()
  const { account, chainId } = useActiveWeb3React()
  const veTokenContract = useVeTokenContract()
  const [avLockedTime, setAvLockedTime] = useState("")
  const [circulationSupply, setCirculationSupply] = useState(1)
  const { t } = useTranslation()
  const countDown = (future_time: any) => {
    //unix time
    if (!future_time) return "-"
    const diff_days = moment.unix(future_time).diff(moment(new Date()), "days")
    if (diff_days < 0) return t("AnyTime")
    else return diff_days + " "+t("Days")
  }
  const getAverageLockTime = useCallback(() => {
    if (!chainId) return
    const query = `{
      lockSystemInfos {
        averageLockTime
      }
    }`
    try {
      getGraph(query).then((res: Response) => {
        const averageLockTime =
          res.data.lockSystemInfos?.[0]?.averageLockTime || "0"
        setAvLockedTime(countDown(+moment().unix() + +averageLockTime))
      })
    } catch (e) {
      console.log(e)
      setTimeout(() => {
        getAverageLockTime()
      }, 5000)
    }
  }, [chainId])
  const FeeDistributorForView = useFeeDistributorContract(true);
  const FeeDistributor = useFeeDistributorContract();
  const [myAdminFee, setMyAdminFee] = useState("0");
  const { error: Network_error } = useWeb3React<Web3Provider>()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const gasPrice = useGasPrice()
  const ClaimAdminFee = async () => {
    const transaction = await FeeDistributor?.claim(account,
      { gasPrice: gasPrice },
    )
    return transaction
  }
  const getClaimAdminFee = useCallback(() => {
    FeeDistributorForView?.claim(account).then((res: any) => {
      const value = formatBNToString(res, 18, 2)
      setMyAdminFee(value);
    });
  },[FeeDistributorForView])
  useEffect(() => {
    getClaimAdminFee()
  }, [getClaimAdminFee])
  
  useEffect(() => {
    getAverageLockTime()
  }, [chainId])

  const srsToken = useTokenContract(SRS_TOKEN)
  const getSrsTotalSupply = useCallback(() => {
    srsToken?.totalSupply().then((res: BigNumber) => {
      // console.log(+formatBNToString(res, 18, 2)-570000000)
      setCirculationSupply(+formatBNToString(res, 18, 2) - 570000000)
    })
  }, [srsToken, veTokenContract])
  useEffect(() => {
    getSrsTotalSupply()
  }, [getSrsTotalSupply])

  const [activeProposals, setActiveProposals] = useState([])
  const getActiveProposals = useCallback(() => {
    const SUBGRAGH_URL = "https://hub.snapshot.org/graphql"
    const query = `
      query Proposals {
        proposals (
          first: 20,
          skip: 0,
          where: {
            space_in: ["siriusfinance.eth"]
          },
          orderBy: "created",
          orderDirection: desc
        ) {
          id
          title
          body
          choices
          start
          end
          snapshot
          state
          author
          space {
            id
            name
          }
        }
      }
    `
    try {
      fetch(SUBGRAGH_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query }),
      })
        .then((res) => res.json())
        .then((result: any) => {
          // console.log(result.data.proposals);
          const final_arr = result.data.proposals.filter((proposal:any) => {
            return ["pending","active"].indexOf(proposal.state) > -1
          })
          // console.log(final_arr);
          setActiveProposals(final_arr)
          // const { lockSystemInfos } = result.data;
          // const averageLockTime = lockSystemInfos[0]['averageLockTime'];
          // // console.log(averageLockTime);
          // // console.log(countDown(+moment().unix()+(+averageLockTime)));
          // setAvLockedTime(countDown(+moment().unix()+(+averageLockTime)));
        })
    } catch (e) {
      console.log(e)
      setTimeout(() => {
        getActiveProposals()
      }, 5000)
    }
  }, [])
  useEffect(() => {
    getActiveProposals()
  }, [])

  const VotingPowerInDAO = () => (
    <Box>
      <Typography variant="body2" className="gov_info_Row">
        <span>{t("TotalSRSLocked")}</span>
        <span>{commify(TotalLockSRS)}</span>
      </Typography>
      <Typography variant="body2" className="gov_info_Row">
        <span>{t("ofLockedSRS")}</span>
        <span>
          {((100 * TotalLockSRS) / circulationSupply).toFixed(2)}%
          {/* {+myLockedSrs
          ? `${(+myLockedSrs / +TotalLockSRS).toFixed(4)}%`
          : "-"} */}
        </span>
      </Typography>
      <Typography variant="body2" className="gov_info_Row">
        <span>{t("TotalveSRSminted")}</span>
        <span>{commify(veTokenMinted)}</span>
      </Typography>
      <Typography variant="body2" className="gov_info_Row">
        <span>{t("AverageLockedTime")}</span>
        <span>{avLockedTime || "-"}</span>
      </Typography>
      <Typography variant="body2" className="gov_info_Row">
        <span>{t("Contract")}</span>
        <span>
          <Link
            // color="inherit"
            underline="hover"
            href={getEtherscanLink(
              veTokenContract?.address || "",
              "address",
              chainId,
            )}
            target="_blank"
          >
            {veTokenContract?.address
              ? shortenAddress(veTokenContract?.address)
              : "-"}
          </Link>
        </span>
      </Typography>
    </Box>
  )

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ display: { xs: "none", sm: "block" }, mb: 2 }}
      >
        <Accordion
          defaultExpanded={true}
          sx={{ border: "unset", background: "unset" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: theme.palette.mute.main }} />
            }
          >
            <Typography variant="subtitle2" fontWeight="bolder" style={{display: "flex"}}>
              <img src={GovInfoIcon} className="gov_info_icon" />
              <span style={{display: "flex", alignItems: "center"}}>
                {t("EarnveSRSbylockingSRS")}
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" lineHeight={1.8}>
              {t("governanceInfoIntro")}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          mb: 2,
          [theme.breakpoints.down("sm")]: {
            border: "none",
            background: "none",
            backdropFilter: "unset",
          },
        }}
      >
        <Box p={3}>
          <Typography
            variant="subtitle2"
            className="gov_info_Row"
            fontWeight="bolder"
            mb={2}
          >
            <span>{t("MyveSRSVotingPower")}</span>
            <span className="gov_info_srs_text">
              {+myLockedSrs
                ? `1SRS = ${(+MyVeSRS / +myLockedSrs).toFixed(2)}veSRS`
                : ""}
            </span>
          </Typography>
          <Typography variant="body2" className="gov_info_Row">
            <span>{t("LockedSRS")}</span>
            <span>
              {+myLockedSrs ? commify(myLockedSrs) : "-"}
              <img width="24" src={SRS_ICON} />
            </span>
          </Typography>
          <Typography variant="body2" className="gov_info_Row">
            <span>{t("MintedveSRS")}</span>
            <span>
              {+MyVeSRS ? commify(MyVeSRS) : "-"}
              <img width="24" src={VESRS_ICON} />
            </span>
          </Typography>
          <Typography variant="body2" className="gov_info_Row">
            <span>{t("LockedPeriod")}</span>
            <span>
              {/* {moment.unix(unlockTime).format("DD MMM,YYYY")} */}
              {countDown(unlockTime)}
            </span>
          </Typography>
          <Typography variant="body2" className="gov_info_Row">
            <span>{t("unlockDate")}</span>
            <span>
              {unlockTime ? moment.unix(unlockTime).format("DD MMM,YYYY") : "-"}
            </span>
          </Typography>
          <Typography variant="body2" className="gov_info_Row" mb={1}>
            <span>Admin Fee Reward</span>
            <span>{myAdminFee} SRS</span>
          </Typography>
          <Button
            fullWidth={true}
            size="medium"
            // className={styles.FarmDetailLeftBtn}
            onClick={() => {
              setShowConfirmModal(true)
              // setFinalLPAmount(
              //   +FarmRowData?.unClaimSRS
              //     ? commify(FarmRowData?.unClaimSRS)
              //     : "-",
              // )
            }}
            disabled={
              isUnsupportChainIdError ||
              !account ||
              +myAdminFee === 0
            }
          >
            Claim Admin Fee Reward
          </Button>
        </Box>
        <Divider />

        <Box display={{ sm: "none" }}>
          <Accordion
            defaultExpanded={true}
            sx={{ border: "unset", background: "unset" }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.palette.mute.main }} />
              }
            >
              <Typography variant="subtitle2" fontWeight="bolder">
                
                {t("VotingPowerinDAO")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <VotingPowerInDAO />
            </AccordionDetails>
          </Accordion>
          <Divider />
        </Box>
        <Box p={3} display={{ xs: "none", sm: "block" }}>
          <Typography variant="subtitle2" fontWeight="bolder" mb={2}>
            <span>{t("VotingPowerinDAO")}</span>
          </Typography>
          <VotingPowerInDAO />
        </Box>
      </Paper>
      {activeProposals && activeProposals.length ? (
        <Paper
          variant="outlined"
          sx={{
            [theme.breakpoints.down("sm")]: {
              border: "none",
              background: "none",
              backdropFilter: "unset",
            },
          }}
        >
          <Box p={3}>
            <Typography
              variant="subtitle2"
              className="gov_info_Row"
              sx={{ fontWeight: 700, marginBottom: "10px" }}
            >
              <span>{t("ActiveProposals")}</span>
            </Typography>
            {activeProposals.map((item: any) => (
              <Typography
                variant="body2"
                key={item.id}
                className="gov_info_Row"
              >
                <Link
                  underline="hover"
                  href={`https://vote.sirius.finance/#/proposal/${item.id}`}
                  target="_blank"
                >
                  {item.title}
                </Link>
                {/* <span>{commify(TotalLockSRS)}</span> */}
              </Typography>
            ))}
          </Box>
        </Paper>
      ) : (
        ""
      )}
      <Dialog
        open={!!showConfirmModal}
        onClose={(): void => setShowConfirmModal(false)}
      >
        <ConfirmTransaction
          onClose={(): void => setShowConfirmModal(false)}
          handler={ClaimAdminFee}
          afterCompleted={() => {
            void getClaimAdminFee()
          }}
        />
      </Dialog>
    </>
  )
}

export default GovernanceInfo
