import {
  injectedMetaMaskProvider,
  uauth,
  walletconnect,
  walletlink,
  injectedTalismanProvider,
  injectedSubWalletProvider,
} from "../connectors"

export * from "./pools/common"
export * from "./pools/stablecoinPool"
export * from "./pools/starlayPool"
export * from "./pools/baiMetapool"
export * from "./pools/jpycMetapool"
export * from "./pools/wbtcMetapool"
export * from "./pools/wethMetapool"
export * from "./pools/wbnbMetapool"
export { WBTC_WETH_4SRS_METAPOOL_NAME } from "./pools/wbtc-weth-4srs-metapool"
// export * from "./pools/dotMetapool"
export { DOT_METAPOOL_NAME } from "./pools/dotMetapool"
export * from "./pools/lAstarPool"
export * from "./pools/avaultPool"
export * from "./pools/pusdtMetapool"

import {
  ChainId,
  PoolTypes,
  buildAddresses,
  buildPids,
  Token,
} from "./pools/common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_SWAP_TOKEN,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_POOL_INFO,
  DAI,
  USDC,
  USDT,
} from "./pools/stablecoinPool"
import { STARLAY_POOL_NAME, STARLAY_POOL_INFO } from "./pools/starlayPool"
import { BAI_METAPOOL_NAME, BAI_POOL_INFO } from "./pools/baiMetapool"
import { JPYC_POOL_NAME, JPYC_POOL_INFO } from "./pools/jpycMetapool"
import { WBTC_METAPOOL_NAME, WBTC_POOL_INFO } from "./pools/wbtcMetapool"
import { WETH_METAPOOL_NAME, WETH_POOL_INFO } from "./pools/wethMetapool"
import { WBNB_METAPOOL_NAME, WBNB_POOL_INFO } from "./pools/wbnbMetapool"
import { DOT_METAPOOL_NAME, DOT_POOL_INFO } from "./pools/dotMetapool"
import { LASTR_METAPOOL_NAME, LASTR_POOL_INFO } from "./pools/lAstarPool"
import { WBTC_WETH_4SRS_POOL_INFO, WBTC_WETH_4SRS_METAPOOL_NAME } from './pools/wbtc-weth-4srs-metapool'
import { AVAULT_POOL_NAME, AVAULT_POOL_INFO } from "./pools/avaultPool"
import { PUSDT_METAPOOL_NAME, PUSDT_POOL_INFO } from "./pools/pusdtMetapool"

import { AbstractConnector } from "@web3-react/abstract-connector"
import { BigNumber } from "@ethersproject/bignumber"
import alusdLogo from "../assets/icons/alusd.svg"
import coinbasewalletIcon from "../assets/icons/coinbasewallet.svg"
import feiLogo from "../assets/icons/fei.svg"
import fraxLogo from "../assets/icons/frax.svg"
import usds_logo from "../assets/icons/usds-logo.png"
import lusdLogo from "../assets/icons/lusd.svg"
import metamaskIcon from "../assets/icons/metamask.svg"
import mimLogo from "../assets/icons/mim.png"
import nusdLogo from "../assets/icons/nusd.svg"
import saddleLPTokenLogo from "../assets/icons/saddle_lp_token.svg"
import saddleLogo from "../assets/icons/logo_24.svg"
import srsLogo from "../assets/icons/logo.svg"
import vesrsLogo from "../assets/icons/vesrs.svg"
import speraxLogo from "../assets/icons/sperax.png"
import susdLogo from "../assets/icons/susd.svg"
import talismanIcon from "../assets/icons/talisman.png"
import subwalletIcon from "../assets/icons/subwallet.png"
import unstoppableDomainsLogo from "../assets/icons/unstoppableDomainsLogo.png"
import usdsLogo from "../assets/icons/usds.png"
import walletconnectIcon from "../assets/icons/walletconnect.svg"
import wcusdLogo from "../assets/icons/wcusd.png"

export const NetworkContextName = "NETWORK"
export const BTC_POOL_NAME = "BTC Pool"
export const STABLECOIN_POOL_V2_NAME = "Stablecoin Pool V2"
export const D4_POOL_NAME = "D4 Pool"
export const SUSD_METAPOOL_NAME = "USDS Metapool"
export const SUSD_METAPOOL_V2_NAME = "sUSD Metapool V2"
export const WCUSD_METAPOOL_NAME = "wCUSD Metapool"
export const WCUSD_METAPOOL_V2_NAME = "wCUSD Metapool V2"
export const ARB_USD_POOL_NAME = "arbUSD Pool"
export const USDS_ARB_USD_METAPOOL_NAME = "usds-arbUSDV2 Metapool"
export const OPT_USD_POOL_NAME = "optUSD Pool"
export const FRAX_OPT_USD_METAPOOL_NAME = "frax-optUSD Pool"
export const FRAX_ARB_USD_POOL_V2_NAME = "arbUSDV2 Pool"
export const FTM_USD_POOL_NAME = "ftmUSD Pool"

export type PoolName =
  | typeof BTC_POOL_NAME
  | typeof STABLECOIN_POOL_NAME
  | typeof STABLECOIN_POOL_V2_NAME
  | typeof D4_POOL_NAME
  | typeof SUSD_METAPOOL_NAME
  | typeof SUSD_METAPOOL_V2_NAME
  | typeof WCUSD_METAPOOL_NAME
  | typeof WCUSD_METAPOOL_V2_NAME
  | typeof ARB_USD_POOL_NAME
  | typeof OPT_USD_POOL_NAME
  | typeof FRAX_OPT_USD_METAPOOL_NAME
  | typeof FRAX_ARB_USD_POOL_V2_NAME
  | typeof FTM_USD_POOL_NAME
  | typeof USDS_ARB_USD_METAPOOL_NAME
  | typeof BAI_METAPOOL_NAME
  | typeof STARLAY_POOL_NAME
  | typeof JPYC_POOL_NAME
  | typeof WBTC_METAPOOL_NAME
  | typeof WETH_METAPOOL_NAME
  | typeof WBNB_METAPOOL_NAME
  | typeof DOT_METAPOOL_NAME
  | typeof LASTR_METAPOOL_NAME
  | typeof WBTC_WETH_4SRS_METAPOOL_NAME
  | typeof AVAULT_POOL_NAME
  | typeof PUSDT_METAPOOL_NAME

export const BLOCK_TIME = 13000 // ms

export const SYNTHETIX_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
})

export const SYNTHETIX_EXCHANGE_RATES_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xF68ECd50de7733015318361295547D8E939F93E6",
})

export const BRIDGE_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xa5bD85ed9fA27ba23BfB702989e7218E44fd4706",
})

export const MINICHEF_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x2B0d36FACD61B71CC05ab8F3D2355ec3631C0dd5",
  [ChainId.ARBITRUM]: "0x2069043d7556B1207a505eb459D18d908DF29b55",
  [ChainId.MAINNET]: "0x691ef79e40d909C715BE5e9e93738B3fF7D58534",
})

export const RETROACTIVE_VESTING_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0xBEc49fA140aCaA83533fB00A2BB19bDdd0290f25",
  [ChainId.MAINNET]: "0x5DCA270671935cf3dF78bd8373C22BE250198a03",
})

export const SWAP_MIGRATOR_USD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x9cDeF6e33687F438808766fC133b2E9d1A16AD57",
  [ChainId.HARDHAT]: "0x99bbA657f2BbC93c02D617f8bA121cB8Fc104Acf",
  // [ChainId.ROPSTEN]: "0x9cDeF6e33687F438808766fC133b2E9d1A16AD57",
})

export const GENERALIZED_SWAP_MIGRATOR_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x46866D274E6D9015c5FDc098CE270803e11e3eF4",
  [ChainId.HARDHAT]: "0x202CCe504e04bEd6fC0521238dDf04Bc9E8E15aB",
})

export const SUSD_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x0C8BAe14c9f9BF2c953997C881BEfaC7729FD314",
  [ChainId.HARDHAT]: "0x9d4454B023096f34B160D6B654540c56A1F81688",
  [ChainId.BSCTEST]: "0xeD5f026e5e2223c671dd8dbB8e31100718BAfA39",
  [ChainId.SHIBUYA]: "0xC55F57E3bcaD88A537Aa0855Ca13D0Dc3bDb6307",
})

export const SUSD_META_SWAP_V2_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x824dcD7b044D60df2e89B1bB888e66D8BCf41491",
  [ChainId.HARDHAT]: "0xdbC43Ba45381e02825b14322cDdd15eC4B3164E6",
})

export const SUSD_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x1e35ebF875f8A2185EDf22da02e7dBCa0F5558aB",
  [ChainId.HARDHAT]: "0x809d550fca64d94Bd9F66E60752A544199cfAC3D",
  [ChainId.BSCTEST]: "0xDf78Cc451870B8ef90846B8bFfAA9E89E56eD3Cf",
  [ChainId.SHIBUYA]: "0x8A900E0A443a504F6Da123B4a5297F1A074fCd81",
})

export const SUSD_META_SWAP_V2_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xc66Ed5d7800579220c71f21B1cCa2006B3a95900",
  [ChainId.HARDHAT]: "0xf433C50017d963b6082043445bCD0A54716DDC1d",
})

export const FRAX_OPT_USD_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.OPTIMISM]: "0x88Cc4aA0dd6Cf126b00C012dDa9f6F4fd9388b17",
})

export const USDS_ARB_USD_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0xDCA5b16A96f984ffb2A3022cfF339eb049126101",
})

export const WCUSD_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x3F1d224557afA4365155ea77cE4BC32D5Dae2174",
  [ChainId.HARDHAT]: "0x1429859428C0aBc9C2C47C8Ee9FBaf82cFA0F20f",
})

export const WCUSD_META_SWAP_V2_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xc02D481B52Ae04Ebc76a8882441cfAED45eb8342",
  [ChainId.HARDHAT]: "0x8D03623d799E93E53FeDf96aF88e2879bA1804FA",
})

export const WCUSD_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x401AFbc31ad2A3Bc0eD8960d63eFcDEA749b4849",
  [ChainId.HARDHAT]: "0x922D6956C99E12DFeB3224DEA977D0939758A1Fe",
})

export const WCUSD_META_SWAP_V2_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x9898D87368DE0Bf1f10bbea8dE46c00cC3a2F9F1",
  [ChainId.HARDHAT]: "0xbc51860c89838ec548d7190657874556407423f4",
})

export const STABLECOIN_SWAP_V2_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xaCb83E0633d6605c5001e2Ab59EF3C745547C8C7",
  [ChainId.HARDHAT]: "0xbf9fBFf01664500A33080Da5d437028b07DFcC55",
  [ChainId.ROPSTEN]: "0x64436bC8E7a18fbD7b4D8574f1248DD08Ba19c2E",
})

export const D4_SWAP_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xC69DDcd4DFeF25D8a793241834d4cc4b3668EAD6",
  // [ChainId.ROPSTEN]: "0xa5da0cB57830011c67Cb89e73582e7Bf0f49f81e",
  [ChainId.HARDHAT]: "0x9f1ac54BEF0DD2f6f3462EA0fa94fC62300d3a8e",
})

export const ARB_USD_SWAP_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0xBea9F78090bDB9e662d8CB301A00ad09A5b756e9",
})

export const OPT_USD_SWAP_ADDRESSES = buildAddresses({
  [ChainId.OPTIMISM]: "0x5847f8177221268d279Cf377D0E01aB3FD993628",
})

export const FTM_USD_SWAP_ADDRESSES = buildAddresses({
  [ChainId.FANTOM]: "0xBea9F78090bDB9e662d8CB301A00ad09A5b756e9",
})

export const FRAX_OPT_USD_SWAP_ADDRESSES = buildAddresses({
  [ChainId.OPTIMISM]: "0xc55E8C79e5A6c3216D4023769559D06fa9A7732e",
})

export const USDS_ARB_USD_SWAP_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0x5dD186f8809147F96D3ffC4508F3C82694E58c9c",
})

export const FRAX_ARB_USD_SWAP_V2_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0xfeEa4D1BacB0519E8f952460A70719944fe56Ee0",
})

export const RETROACTIVE_SDL_MERKLETREE_DATA = buildAddresses({
  [ChainId.HARDHAT]: "hardhat.json",
})

export const SUSD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x8Fa31c1b33De16bf05c38AF20329f22D544aD64c",
  [ChainId.HARDHAT]: "0xBeaAFDA2E17fC95E69Dc06878039d274E0d2B21A",
  [ChainId.BSCTEST]: "0xA08C60ADb22dfaC4076cce0efce445b38EFaB857",
  [ChainId.SHIBUYA]: "0x2939FbAB37A3B04d3274695ffCA0004f1576f3D1",
})

export const SUSD_SWAP_TOKEN_V2_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xb6214a9d18f5Bf34A23a355114A03bE4f7D804fa",
  [ChainId.HARDHAT]: "0xb7c7142Cb2cBf105Eca46A00dDD0Fb3DD7698E8b",
})

export const STABLECOIN_SWAP_V2_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x5f86558387293b6009d7896A61fcc86C17808D62",
  [ChainId.HARDHAT]: "0xC863F1F636fddce400E7515eCBDAbbEc4d1E0390",
  [ChainId.ROPSTEN]: "0x4AA2a97E0A06cbaf9340CA2Ead20b581492D8F83",
})

export const WCUSD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x78179d49C13c4ECa14C69545ec172Ba0179EAE6B",
  [ChainId.HARDHAT]: "0x465Df401621060aE6330C13cA7A0baa2B0a9d66D",
})

export const FTM_USD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.FANTOM]: "0xc969dD0A7AB0F8a0C5A69C0839dB39b6C928bC08",
})

export const WCUSD_SWAP_TOKEN_V2_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x5F7872490a9B405946376dd40fCbDeF521F13e3f",
  [ChainId.HARDHAT]: "0x5c5baB00ef196517c81097dA095948317d458f21",
})

export const ARB_USD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0xc969dD0A7AB0F8a0C5A69C0839dB39b6C928bC08",
})

export const OPT_USD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.OPTIMISM]: "0xcCf860874cbF2d615192a4C4455580B4d622D3B9",
})

export const FRAX_OPT_USD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.OPTIMISM]: "0xfF5fa61Eb9b5cDD63bdFa16EF029d5313457925A",
})

export const USDS_ARB_USD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0xa815b134294580692482E321dD1A191aC1454192",
})

export const FRAX_ARB_USD_SWAP_V2_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0x0a20c2FFa10cD43F67D06170422505b7D6fC0953",
})

export const DEPOSIT_REWARDS_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x14Fa757E7De71543D91E670e7c609af0be361C96",
})

export const D4_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xd48cF4D7FB0824CC8bAe055dF3092584d0a1726A",
  [ChainId.ROPSTEN]: "0xA2b37a2c1F5E523f867137D2394Da1AC2283f473",
  [ChainId.HARDHAT]: "0x2d2c18F63D2144161B38844dCd529124Fbb93cA2",
})

export const SRS_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x4EE6eCAD1c2Dae9f525404De8555724e3c35d07B",
  [ChainId.MAINNET]: "0xf1Dc500FdE233A4055e25e5BbF516372BC4F6871",
  [ChainId.ARBITRUM]: "0x75c9bc761d88f70156daf83aa010e84680baf131",
  [ChainId.OPTIMISM]: "0xa29b548056c3fd0f68bad9d4829ec4e66f22f796",
  [ChainId.ROPSTEN]: "0xaD6D458402F60fD3Bd25163575031ACDce07538D",
  [ChainId.FANTOM]: "",
  [ChainId.BSCTEST]: "0xf1446Bfba9D94fa848FA9b778ce9E79bEAbB2afa",
  [ChainId.SHIBUYA]: "0x1C0e963cfc6769956219811133E6623F905ef287",
  [ChainId.ASTAR]: "0x9448610696659de8F72e1831d392214aE1ca4838",
})

export const FARMS_ADDRESSES = buildAddresses({
  [ChainId.BSCTEST]: "0x239A15C4c37F2AA9c229162d17540055B30161f3",
  [ChainId.SHIBUYA]: "0xf733742137344c19EEF476D41613575BA86Dbfe9",
  [ChainId.ASTAR]: "0x9464c206e0BF6310a130e690209dbFf1a7D4Dc65",
})

export const SUSD_FARM_ADDRESSES = buildAddresses({
  [ChainId.BSCTEST]: "0x7c5F28D110Edb0F929D711f104eD6F8E1E599176",
  [ChainId.SHIBUYA]: "0xcbb6830830F08dc1a9a36eE6D8173E87DB5e629D",
})

export const FARM_MINTER_ADDRESSES = buildAddresses({
  [ChainId.BSCTEST]: "0xf9D2b8Efe545b04E5826DADe439Cf25778c7EeBb",
  [ChainId.SHIBUYA]: "0xC616CF68490e2480050290B9bA126F69F177a58d",
})

export const FEE_DISTRIBUTOR_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x3D0264AA418b05b3f4950Be7E764D4e537544fb3",
})

export const VE_TOKEN_ADDRESSES = buildAddresses({
  //'VotingEscrow'
  [ChainId.BSCTEST]: "0xD42b67313234816B341240344cc6C39A4726ba73",
  [ChainId.SHIBUYA]: "0x4c6Ab6874E0C87F4827a4B0401D4d857D03492ae",
  [ChainId.ASTAR]: "0xc9D383f1e6E5270D77ad8e198729e237b60b6397",
})

export const TEST_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x0284EaB1df41eB58bf0C2232408791668d4e0D11",
})

export const ARTH_ROUTER_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0xE915D2393a08a00c5A463053edD31bAe2199b9e7",
})
export const ORU_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0xCdB32eEd99AA19D39e5d6EC45ba74dC4afeC549F",
})
export const SPA_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0x5575552988A3A80504bBaeB1311674fCFd40aD4B",
})

export const SPA = new Token(
  SPA_TOKEN_ADDRESSES,
  18,
  "SPA",
  "sperax",
  "Sperax",
  speraxLogo,
  false,
  false,
)

export const SRS_TOKEN = new Token(
  SRS_TOKEN_ADDRESSES,
  18,
  "SRS",
  "saddle-dao", // TBD
  "Saddle DAO",
  srsLogo,
  false,
  false,
)
export const VE_TOKEN = new Token(
  VE_TOKEN_ADDRESSES,
  18,
  "veSRS",
  "saddle-dao", // TBD
  "Saddle DAO",
  vesrsLogo,
  false,
  false,
)
export const TEST_TOKEN = new Token(
  TEST_TOKEN_ADDRESSES,
  18,
  "JPYC",
  "saddle-dao", // TBD
  "Saddle DAO",
  srsLogo,
  false,
  false,
)
export const ORU_TOKEN = new Token(
  ORU_TOKEN_ADDRESSES,
  18,
  "ORU",
  "ORU",
  "ORU",
  susdLogo,
  false,
  false,
)

export const SUSD_SWAP_TOKEN = new Token(
  SUSD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "5SRS",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  usds_logo,
  false,
  true,
)

export const SUSD_SWAP_V2_TOKEN = new Token(
  SUSD_SWAP_TOKEN_V2_CONTRACT_ADDRESSES,
  18,
  "saddleSUSD-V2",
  "saddlesusd-v2",
  "Saddle sUSD/saddleUSD-V2 V2",
  saddleLPTokenLogo,
  false,
  true,
)

export const STABLECOIN_SWAP_V2_TOKEN = new Token(
  STABLECOIN_SWAP_V2_TOKEN_CONTRACT_ADDRESSES,
  18,
  "saddleUSD-V2",
  "saddleusd-v2",
  "Saddle DAI/USDC/USDT V2",
  saddleLPTokenLogo,
  false,
  true,
)

export const WCUSD_SWAP_TOKEN = new Token(
  WCUSD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "saddlewCUSD",
  "saddlewcusd",
  "Saddle wCUSD/saddleUSD-V2",
  saddleLPTokenLogo,
  false,
  true,
)

export const WCUSD_SWAP_V2_TOKEN = new Token(
  WCUSD_SWAP_TOKEN_V2_CONTRACT_ADDRESSES,
  18,
  "saddlewCUSD-V2",
  "saddlewcusd-v2",
  "Saddle wCUSD/saddleUSD-V2 V2",
  saddleLPTokenLogo,
  false,
  true,
)

export const ARB_USD_SWAP_TOKEN = new Token(
  ARB_USD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "saddleArbUSD",
  "saddlearbUSD",
  "Saddle nUSD/MIM/USDC/USDT",
  saddleLogo,
  false,
  true,
)

export const FTM_USD_SWAP_TOKEN = new Token(
  FTM_USD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "saddleFtmUSD",
  "saddleftmUSD",
  "Saddle FRAX/USDC",
  saddleLogo,
  false,
  true,
)

export const OPT_USD_SWAP_TOKEN = new Token(
  OPT_USD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "saddleOptUSD",
  "saddleoptUSD",
  "Saddle DAI/USDC/USDT",
  saddleLogo,
  false,
  true,
)

export const FRAX_OPT_USD_SWAP_TOKEN = new Token(
  FRAX_OPT_USD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "frax-saddleOptUSD",
  "frax-saddleoptUSD",
  "Saddle FRAX/saddleOptUsd",
  saddleLogo,
  false,
  true,
)

export const USDS_ARB_USD_SWAP_TOKEN = new Token(
  USDS_ARB_USD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "usds-saddleArbUSD",
  "usds-saddlearbUSD",
  "Saddle USDS/saddleArbUsd",
  saddleLogo,
  false,
  true,
)

export const FRAX_ARB_USD_SWAP_V2_TOKEN = new Token(
  FRAX_ARB_USD_SWAP_V2_TOKEN_CONTRACT_ADDRESSES,
  18,
  "saddleFraxArbUSD-V2",
  "saddlefraxarbUSD-v2",
  "Saddle FRAX/USDC/USDT",
  saddleLogo,
  false,
  true,
)

export const D4_SWAP_TOKEN = new Token(
  D4_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "saddleD4",
  "saddled4",
  "Saddle alUSD/FEI/FRAX/LUSD",
  saddleLPTokenLogo,
  false,
  true,
)

export const EXTRA_TOKENS_MAP = {
  [SRS_TOKEN.symbol]: SRS_TOKEN,
  [ORU_TOKEN.symbol]: ORU_TOKEN,
  [TEST_TOKEN.symbol]: TEST_TOKEN,
}

export const AIRDROP_TOKENS_MAP = {
  [SRS_TOKEN.symbol]: SRS_TOKEN,
}

// Stablecoins
const WCUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xad3e3fc59dff318beceaab7d00eb4f68b1ecf195",
  [ChainId.HARDHAT]: "0xFD471836031dc5108809D173A067e8486B9047A3",
})
export const WCUSD = new Token(
  WCUSD_CONTRACT_ADDRESSES,
  18,
  "wCUSD",
  "wrapped-celo-dollar",
  "Wrapped Celo USD",
  wcusdLogo,
)

const SUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51",
  [ChainId.HARDHAT]: "0x0E801D84Fa97b50751Dbf25036d067dCf18858bF",
  [ChainId.BSCTEST]: "0x0BD3669519E255b8bc0ec72bBA722F70767A4a8c",
  [ChainId.SHIBUYA]: "0x7818E79777a70381a27FfCcE52e55C18DFFAE8CA",
})
export const SUSD = new Token(
  SUSD_CONTRACT_ADDRESSES,
  18,
  "USDS",
  "nusd",
  "sUSD",
  susdLogo,
  true,
)

const NUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0x2913e812cf0dcca30fb28e6cac3d2dcff4497688",
})
export const NUSD = new Token(
  NUSD_CONTRACT_ADDRESSES,
  18,
  "nUSD",
  "nusd",
  "nUSD",
  nusdLogo,
)

const MIM_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
})
export const MIM = new Token(
  MIM_CONTRACT_ADDRESSES,
  18,
  "MIM",
  "magic-internet-money",
  "Magic Internet Money",
  mimLogo,
)

const FRAX_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
  [ChainId.ROPSTEN]: "0xb295E36469C8Aef7d76b661aD5af02cdB258D662",
  [ChainId.HARDHAT]: "0x851356ae760d987E095750cCeb3bC6014560891C",
  [ChainId.ARBITRUM]: "0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
  [ChainId.OPTIMISM]: "0x2E3D870790dC77A83DD1d18184Acc7439A53f475",
  [ChainId.FANTOM]: "0xdc301622e621166bd8e82f2ca0a26c13ad0be355",
})

const USDS_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ARBITRUM]: "0xd74f5255d557944cf7dd0e45ff521520002d5748",
})

export const FRAX = new Token(
  FRAX_CONTRACT_ADDRESSES,
  18,
  "FRAX",
  "frax",
  "Frax",
  fraxLogo,
)

export const USDS = new Token(
  USDS_CONTRACT_ADDRESSES,
  18,
  "USDs",
  "sperax-usd",
  "USDs",
  usdsLogo,
)

export const FRAX_ARB_USD_POOL_V2_TOKENS = [FRAX, USDC, USDT]
export const SUSD_POOL_TOKENS = [SUSD, ...STABLECOIN_POOL_TOKENS]
export const SUSD_UNDERLYING_POOL_TOKENS = [SUSD, STABLECOIN_SWAP_TOKEN]
export const ARB_USD_POOL_TOKENS = [NUSD, MIM, USDC, USDT]
export const USDS_ARB_USD_POOL_TOKENS = [USDS, ...FRAX_ARB_USD_POOL_V2_TOKENS]
export const OPT_USD_POOL_TOKENS = [DAI, USDC, USDT]
export const FRAX_OPT_USD_POOL_TOKENS = [FRAX, ...OPT_USD_POOL_TOKENS]
export const FRAX_OPT_USD_UNDERLYING_POOL_TOKENS = [FRAX, OPT_USD_SWAP_TOKEN]
export const USDS_ARB_USD_UNDERLYING_POOL_TOKENS = [
  USDS,
  FRAX_ARB_USD_SWAP_V2_TOKEN,
]
export const FTM_USD_POOL_TOKENS = [FRAX, USDC]

const ALUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xBC6DA0FE9aD5f3b0d58160288917AA56653660E9",
  [ChainId.ROPSTEN]: "0x8b7a92FdbC77c6d8c61644D118c37D813B2069C4",
  [ChainId.HARDHAT]: "0x84eA74d481Ee0A5332c457a4d796187F6Ba67fEB",
})
export const ALUSD = new Token(
  ALUSD_CONTRACT_ADDRESSES,
  18,
  "alUSD",
  "alchemix-usd",
  "Alchemix USD",
  alusdLogo,
)

const FEI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x956F47F50A910163D8BF957Cf5846D573E7f87CA",
  [ChainId.ROPSTEN]: "0x02020a3006587080a00d6675AFfACC99344521AC",
  [ChainId.HARDHAT]: "0xa82fF9aFd8f496c3d6ac40E2a0F282E47488CFc9",
})
export const FEI = new Token(
  FEI_CONTRACT_ADDRESSES,
  18,
  "FEI",
  "fei-usd",
  "Fei Protocol",
  feiLogo,
)

const LUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
  [ChainId.ROPSTEN]: "0x440d96e36f1A087deFdB5206b5e53DD2e526840B",
  [ChainId.HARDHAT]: "0x95401dc811bb5740090279Ba06cfA8fcF6113778",
})
export const LUSD = new Token(
  LUSD_CONTRACT_ADDRESSES,
  18,
  "LUSD",
  "liquity-usd",
  "Liquity USD",
  lusdLogo,
)

export const D4_POOL_TOKENS = [ALUSD, FEI, FRAX, LUSD]

export const WCUSD_POOL_TOKENS = [WCUSD, ...STABLECOIN_POOL_TOKENS]
export const WCUSD_UNDERLYING_POOL_TOKENS = [WCUSD, STABLECOIN_SWAP_V2_TOKEN]

export type Pool = {
  name: PoolName
  lpToken: Token
  poolTokens: Token[]
  isSynthetic: boolean
  addresses: { [chainId in ChainId]: string }
  farmAddresses?: { [chainId in ChainId]: string }
  type: PoolTypes
  route: string
  metaSwapAddresses?: { [chainId in ChainId]: string }
  underlyingPoolTokens?: Token[]
  underlyingPool?: PoolName
  isOutdated?: boolean // pool can be outdated but not have a migration target
  rewardPids: { [chainId in ChainId]: number | null }
  show?: boolean // 是否在 swap pool farm 中显示
  hot?: boolean  // 是否显示 hot 角标
  unbalancePercent?: number // pool 某个 token 低于这个值算失衡
  unsort?: boolean // 不参与排序
}
export type PoolsMap = {
  [poolName: string]: Pool
}
export const POOLS_MAP: PoolsMap = {
  [PUSDT_METAPOOL_NAME]: PUSDT_POOL_INFO as Pool,
  [LASTR_METAPOOL_NAME]: LASTR_POOL_INFO as Pool,
  [STABLECOIN_POOL_NAME as PoolName]: STABLECOIN_POOL_INFO as Pool,
  [AVAULT_POOL_NAME]: AVAULT_POOL_INFO as Pool,
  [STARLAY_POOL_NAME as PoolName]: STARLAY_POOL_INFO as Pool,
  // [STABLECOIN_POOL_V2_NAME]: {
  //   name: STABLECOIN_POOL_V2_NAME,
  //   addresses: STABLECOIN_SWAP_V2_ADDRESSES,
  //   lpToken: STABLECOIN_SWAP_V2_TOKEN,
  //   poolTokens: STABLECOIN_POOL_TOKENS,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   route: "usdv2",
  //   rewardPids: buildPids({ [ChainId.MAINNET]: 3, [ChainId.HARDHAT]: 3 }),
  // },
  // [D4_POOL_NAME]: {
  //   name: D4_POOL_NAME,
  //   addresses: D4_SWAP_ADDRESSES,
  //   lpToken: D4_SWAP_TOKEN,
  //   poolTokens: D4_POOL_TOKENS,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   route: "d4",
  //   rewardPids: buildPids({ [ChainId.MAINNET]: 2, [ChainId.HARDHAT]: 2 }),
  // },
  // [ARB_USD_POOL_NAME]: {
  //   name: ARB_USD_POOL_NAME,
  //   addresses: ARB_USD_SWAP_ADDRESSES,
  //   lpToken: ARB_USD_SWAP_TOKEN,
  //   poolTokens: ARB_USD_POOL_TOKENS,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   route: "arbusd",
  //   rewardPids: buildPids({ [ChainId.ARBITRUM]: 1 }),
  // },
  // [OPT_USD_POOL_NAME]: {
  //   name: OPT_USD_POOL_NAME,
  //   addresses: OPT_USD_SWAP_ADDRESSES,
  //   lpToken: OPT_USD_SWAP_TOKEN,
  //   poolTokens: OPT_USD_POOL_TOKENS,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   route: "optusd",
  //   rewardPids: buildPids({}),
  // },
  // [FRAX_OPT_USD_METAPOOL_NAME]: {
  //   name: FRAX_OPT_USD_METAPOOL_NAME,
  //   addresses: FRAX_OPT_USD_META_SWAP_DEPOSIT_ADDRESSES,
  //   lpToken: FRAX_OPT_USD_SWAP_TOKEN,
  //   poolTokens: FRAX_OPT_USD_POOL_TOKENS,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   metaSwapAddresses: FRAX_OPT_USD_SWAP_ADDRESSES,
  //   underlyingPoolTokens: FRAX_OPT_USD_UNDERLYING_POOL_TOKENS,
  //   underlyingPool: OPT_USD_POOL_NAME,
  //   route: "frax-optusd",
  //   rewardPids: buildPids({}),
  // },
  // [USDS_ARB_USD_METAPOOL_NAME]: {
  //   name: USDS_ARB_USD_METAPOOL_NAME,
  //   addresses: USDS_ARB_USD_META_SWAP_DEPOSIT_ADDRESSES,
  //   lpToken: USDS_ARB_USD_SWAP_TOKEN,
  //   poolTokens: USDS_ARB_USD_POOL_TOKENS,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   metaSwapAddresses: USDS_ARB_USD_SWAP_ADDRESSES,
  //   underlyingPoolTokens: USDS_ARB_USD_UNDERLYING_POOL_TOKENS,
  //   underlyingPool: FRAX_ARB_USD_POOL_V2_NAME,
  //   route: "usds-arbusd",
  //   rewardPids: buildPids({ [ChainId.ARBITRUM]: 2 }),
  // },
  // [FRAX_ARB_USD_POOL_V2_NAME]: {
  //   name: FRAX_ARB_USD_POOL_V2_NAME,
  //   addresses: FRAX_ARB_USD_SWAP_V2_ADDRESSES,
  //   lpToken: FRAX_ARB_USD_SWAP_V2_TOKEN,
  //   poolTokens: FRAX_ARB_USD_POOL_V2_TOKENS,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   route: "frax-arbusdv2",
  //   rewardPids: buildPids({}),
  // },
  // [SUSD_METAPOOL_V2_NAME]: {
  //   name: SUSD_METAPOOL_V2_NAME,
  //   lpToken: SUSD_SWAP_V2_TOKEN,
  //   poolTokens: SUSD_POOL_TOKENS,
  //   addresses: SUSD_META_SWAP_V2_DEPOSIT_ADDRESSES,
  //   isSynthetic: true,
  //   type: PoolTypes.USD,
  //   metaSwapAddresses: SUSD_META_SWAP_V2_ADDRESSES,
  //   underlyingPoolTokens: SUSD_UNDERLYING_POOL_TOKENS,
  //   underlyingPool: STABLECOIN_POOL_V2_NAME,
  //   route: "susdv2",
  //   rewardPids: buildPids({ [ChainId.MAINNET]: 6 }),
  // },
  // [SUSD_METAPOOL_NAME]: {
  //   name: SUSD_METAPOOL_NAME,
  //   lpToken: SUSD_SWAP_TOKEN,
  //   poolTokens: SUSD_POOL_TOKENS,
  //   addresses: SUSD_META_SWAP_DEPOSIT_ADDRESSES,
  //   farmAddresses: SUSD_FARM_ADDRESSES,
  //   isSynthetic: true,
  //   type: PoolTypes.USD,
  //   metaSwapAddresses: SUSD_META_SWAP_ADDRESSES,
  //   underlyingPoolTokens: SUSD_UNDERLYING_POOL_TOKENS,
  //   underlyingPool: STABLECOIN_POOL_NAME,
  //   route: "susd",
  //   show: true,
  //   rewardPids: buildPids({}),
  // },
  // [WCUSD_METAPOOL_NAME]: {
  //   name: WCUSD_METAPOOL_NAME,
  //   lpToken: WCUSD_SWAP_TOKEN,
  //   poolTokens: WCUSD_POOL_TOKENS,
  //   addresses: WCUSD_META_SWAP_DEPOSIT_ADDRESSES,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   metaSwapAddresses: WCUSD_META_SWAP_ADDRESSES,
  //   underlyingPoolTokens: WCUSD_UNDERLYING_POOL_TOKENS,
  //   underlyingPool: STABLECOIN_POOL_V2_NAME,
  //   route: "wcusd",
  //   rewardPids: buildPids({}),
  // },
  // [WCUSD_METAPOOL_V2_NAME]: {
  //   name: WCUSD_METAPOOL_V2_NAME,
  //   lpToken: WCUSD_SWAP_V2_TOKEN,
  //   poolTokens: WCUSD_POOL_TOKENS,
  //   addresses: WCUSD_META_SWAP_V2_DEPOSIT_ADDRESSES,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   metaSwapAddresses: WCUSD_META_SWAP_V2_ADDRESSES,
  //   underlyingPoolTokens: WCUSD_UNDERLYING_POOL_TOKENS,
  //   underlyingPool: STABLECOIN_POOL_V2_NAME,
  //   route: "wcusdv2",
  //   rewardPids: buildPids({ [ChainId.MAINNET]: 7 }),
  // },
  // [FTM_USD_POOL_NAME]: {
  //   name: FTM_USD_POOL_NAME,
  //   addresses: FTM_USD_SWAP_ADDRESSES,
  //   lpToken: FTM_USD_SWAP_TOKEN,
  //   poolTokens: FTM_USD_POOL_TOKENS,
  //   isSynthetic: false,
  //   type: PoolTypes.USD,
  //   route: "ftmusd",
  //   rewardPids: buildPids({}),
  // },
  [BAI_METAPOOL_NAME]: BAI_POOL_INFO as Pool,
  [JPYC_POOL_NAME]: JPYC_POOL_INFO as Pool,
  [WBTC_METAPOOL_NAME]: WBTC_POOL_INFO as Pool,
  [WETH_METAPOOL_NAME]: WETH_POOL_INFO as Pool,
  [WBNB_METAPOOL_NAME]: WBNB_POOL_INFO as Pool,
  // [DOT_METAPOOL_NAME]: DOT_POOL_INFO as Pool,
  [WBTC_WETH_4SRS_METAPOOL_NAME]: WBTC_WETH_4SRS_POOL_INFO as Pool,
}

export function isLegacySwapABIPool(poolName: string): boolean {
  return new Set([BTC_POOL_NAME]).has(poolName)
}
export function isMetaPool(poolName = ""): boolean {
  return new Set([
    FRAX_OPT_USD_METAPOOL_NAME,
    SUSD_METAPOOL_NAME,
    SUSD_METAPOOL_V2_NAME,
    USDS_ARB_USD_METAPOOL_NAME,
    WCUSD_METAPOOL_NAME,
    WCUSD_METAPOOL_V2_NAME,
    BAI_METAPOOL_NAME,
    PUSDT_METAPOOL_NAME,
  ]).has(poolName)
}

export function isXTriSwapPool(poolName = ""): boolean {
  return new Set([
    WBTC_WETH_4SRS_METAPOOL_NAME
  ]).has(poolName)
}

export function isCryptoPool(poolName = ""): boolean {
  return new Set([
    JPYC_POOL_NAME,
    WBTC_METAPOOL_NAME,
    WETH_METAPOOL_NAME,
    WBNB_METAPOOL_NAME,
    DOT_METAPOOL_NAME,
    WBTC_WETH_4SRS_METAPOOL_NAME
  ]).has(poolName)
}

export function isNativePool(poolName = ""): boolean {
  return new Set([
    LASTR_METAPOOL_NAME,
  ]).has(poolName)
}

// maps a symbol string to a token object
export type TokensMap = {
  [symbol: string]: Token
}

export const TOKENS_MAP = Object.keys(POOLS_MAP).reduce((acc, poolName) => {
  const pool = POOLS_MAP[poolName as PoolName]
  const newAcc = { ...acc }
  pool.poolTokens.forEach((token) => {
    newAcc[token.symbol] = token
  })
  newAcc[pool.lpToken.symbol] = pool.lpToken
  return newAcc
}, {} as TokensMap)

export type TokenToPoolsMap = {
  [tokenSymbol: string]: string[]
}
export const TOKEN_TO_POOLS_MAP = Object.keys(POOLS_MAP).reduce(
  (acc, poolName) => {
    const pool = POOLS_MAP[poolName as PoolName]
    const newAcc = { ...acc }
    pool.poolTokens.forEach((token) => {
      newAcc[token.symbol] = (newAcc[token.symbol] || []).concat(
        poolName as PoolName,
      )
    })
    return newAcc
  },
  {} as TokenToPoolsMap,
)

export type LPTokenToPoolsMap = {
  [tokenSymbol: string]: PoolName
}
export const LPTOKEN_TO_POOL_MAP = Object.keys(POOLS_MAP).reduce(
  (acc, poolName) => {
    const pool = POOLS_MAP[poolName as PoolName]
    const newAcc = { ...acc }
    newAcc[pool.lpToken.symbol] = poolName as PoolName
    return newAcc
  },
  {} as LPTokenToPoolsMap,
)

export const TRANSACTION_TYPES = {
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  SWAP: "SWAP",
  MIGRATE: "MIGRATE",
  STAKE_OR_CLAIM: "STAKE_OR_CLAIM",
}

export const POOL_FEE_PRECISION = 10

export enum SWAP_TYPES {
  DIRECT = "swapDirect", // route length 2
  SYNTH_TO_SYNTH = "swapSynthToSynth", // route length 2
  SYNTH_TO_TOKEN = "swapSynthToToken", // route length 3
  TOKEN_TO_SYNTH = "swapTokenToSynth", // route length 3
  TOKEN_TO_TOKEN = "swapTokenToToken", // route length 4
  INVALID = "invalid",
}

export function getIsVirtualSwap(swapType: SWAP_TYPES): boolean {
  return (
    swapType === SWAP_TYPES.SYNTH_TO_SYNTH ||
    swapType === SWAP_TYPES.SYNTH_TO_TOKEN ||
    swapType === SWAP_TYPES.TOKEN_TO_SYNTH ||
    swapType === SWAP_TYPES.TOKEN_TO_TOKEN
  )
}

export const SWAP_CONTRACT_GAS_ESTIMATES_MAP = {
  [SWAP_TYPES.INVALID]: BigNumber.from("999999999"), // 999,999,999
  [SWAP_TYPES.DIRECT]: BigNumber.from("200000"), // 157,807
  [SWAP_TYPES.TOKEN_TO_TOKEN]: BigNumber.from("2000000"), // 1,676,837
  [SWAP_TYPES.TOKEN_TO_SYNTH]: BigNumber.from("2000000"), // 1,655,502
  [SWAP_TYPES.SYNTH_TO_TOKEN]: BigNumber.from("1500000"), // 1,153,654
  [SWAP_TYPES.SYNTH_TO_SYNTH]: BigNumber.from("700000"), // 681,128 // TODO: https://github.com/saddle-finance/saddle-frontend/issues/471
  addLiquidity: BigNumber.from("400000"), // 386,555
  removeLiquidityImbalance: BigNumber.from("350000"), // 318,231
  removeLiquidityOneToken: BigNumber.from("250000"), // 232,947
  migrate: BigNumber.from("650000"), // 619,126
  virtualSwapSettleOrWithdraw: BigNumber.from("400000"),
}

export interface WalletInfo {
  name: string
  icon: string
  connector: AbstractConnector
  globalName?: string // name attached to window. eg: window.<globalName>
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    name: "MetaMask",
    icon: metamaskIcon,
    connector: injectedMetaMaskProvider,
    globalName: "ethereum",
  },
  TALISMAN: {
    name: "Talisman",
    icon: talismanIcon,
    connector: injectedTalismanProvider,
    globalName: "talismanEth",
  },
  SUB_WALLET: {
    name: "SubWallet",
    icon: subwalletIcon,
    connector: injectedSubWalletProvider,
    globalName: "SubWallet",
  },
  // UNSTOPPABLE_DOMAINS: {
  //   name: "Unstoppable Domains",
  //   icon: unstoppableDomainsLogo,
  //   connector: uauth,
  // },
  WALLET_CONNECT: {
    name: "WalletConnect",
    icon: walletconnectIcon,
    connector: walletconnect,
    globalName: "ethereum",
  },
  // WALLET_LINK: {
  //   name: "Coinbase Wallet",
  //   icon: coinbasewalletIcon,
  //   connector: walletlink,
  // },
}

// "SADDLE" in bytes32 form
export const SYNTH_TRACKING_ID =
  "0x534144444c450000000000000000000000000000000000000000000000000000"

// FLAGS
export const IS_VIRTUAL_SWAP_ACTIVE = true
export const IS_L2_SUPPORTED = true
export const IS_SDL_LIVE = true
// FLAGS END

export const GRAPH_URL = "https://squid.subsquid.io/sirius/v/v8/graphql"

export function isDisableDeposit(poolName?: string): boolean {
  const pools = [WBTC_METAPOOL_NAME, WETH_METAPOOL_NAME, WBNB_METAPOOL_NAME]
  return !!poolName && pools.includes(poolName)
}

// exchange
export const EXCH_URL = "https://api.sirius.finance"
