import React, { ReactElement, ReactNode, useState, useEffect } from "react"
import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  useTheme,
  IconButton,
  Container,
  Typography,
  Link,
  LinkProps,
  Drawer,
  Menu,
  MenuItem,
} from "@mui/material"
import { NavLink, useLocation } from "react-router-dom"
import { CallMade, ArrowDropDown } from "@mui/icons-material"
import Web3Status from "./Web3Status"
import { useActiveWeb3React } from "../hooks"
import { ChainId } from "../constants"
import { useWeb3React } from "@web3-react/core"
import AdvancedOptions from "./AdvancedOptions"
import TopMenuOthers from "./TopMenuOthers"
import TopMenuLanguage from "./TopMenuLanguage"
import { Links as docLinks, SocialChannelsDOM } from "./OfficialLinks"
import logoSVG from "../assets/icons/logo.svg"
import { Link as ReactLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import SRSPrice from "./SRSPrice"
import styles from "./TopMenu.module.scss"

const pageLinks = [
  "dashboard",
  "swap",
  "pools",
  "farms",
  "governance",
  "vote",
  "leaderboard",
  "community",
]

function NavItem(props: { text: string; to?: string; className?: string }) {
  const { text, to, className } = props
  const { t } = useTranslation()
  return (
    <NavLink
      className={`${styles.item} ${className}`}
      activeClassName={styles.itemActive}
      to={to || `/${text}`}
    >
      {t(text)}
    </NavLink>
  )
}

function ExternalLink(
  props: LinkProps & { className?: string; children?: ReactNode },
) {
  const { className, children } = props
  return (
    <Link
      target="_blank"
      rel="noreferrer"
      {...props}
      className={`${styles.item} ${className}`}
      sx={{ textTransform: "none!important" }}
    >
      {children}
    </Link>
  )
}

function TopMenu(): ReactElement {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const theme = useTheme()
  const { pathname } = useLocation()
  const activeTab = pathname.split("/")[1]
  const [isDeepLink, setIsDeepLink] = useState(false)

  const { account } = useActiveWeb3React()
  const { chainId } = useWeb3React()
  const { t } = useTranslation()

  const [anchDao, setAnchDao] = useState<Element | null>(null)
  const daoClose = () => setAnchDao(null)
  const [anchMore, setAnchMore] = useState<Element | null>(null)
  const moreClose = () => setAnchMore(null)

  useEffect(() => {
    if (pathname.split("/").length > 2) {
      setIsDeepLink(true)
    } else {
      setIsDeepLink(false)
    }
  }, [pathname, setIsDeepLink])

  const MobileNavPopup = (
    <Drawer
      anchor="top"
      open={showMobileMenu}
      onClick={() => setShowMobileMenu(false)}
      onClose={() => setShowMobileMenu(false)}
      elevation={0}
      ModalProps={{
        sx: { zIndex: 1099 },
        BackdropProps: { className: styles.mobileNavPopupBg },
      }}
      PaperProps={{ sx: { backgroundColor: "transparent", border: "none" } }}
    >
      <Box className={styles.mobileNavPopup}>
        {pageLinks.map((link) => (
          <Box key={link} sx={{ mt: 3 }}>
            <NavItem text={link} className={styles.itemBig} />
          </Box>
        ))}
        {/* <Box sx={{ mt: 3 }}>
          <NavItem
            text="claim"
            to="/vesting-claim"
            className={styles.itemBig}
          />
        </Box> */}
        <Box sx={{ mt: 3 }}>
          <ExternalLink
            className={styles.itemBig}
            href="https://cbridge.celer.network/#/transfer"
          >
            cBridge
            <CallMade />
          </ExternalLink>
        </Box>
        <Box sx={{ flexGrow: 1, mt: 3 }} />
        <Box mb={1}>
          <SRSPrice />
        </Box>
        <Box>
          {docLinks.map((item) => (
            <ExternalLink
              key={item.key}
              className={styles.itemSmall}
              href={item.link}
            >
              {item.name}
            </ExternalLink>
          ))}
        </Box>
        <Box>
          <SocialChannelsDOM />
        </Box>
      </Box>
    </Drawer>
  )

  const PcNav = (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      <Stack
        className={styles.navs}
        direction="row"
        spacing={{ md: 1, lg: 3, xl: 5 }}
      >
        <NavItem text="dashboard" />
        <NavItem text="swap" />
        <NavItem text="pools" />
        <NavItem text="farms" />

        <Box
          className={styles.item}
          onClick={(e) => setAnchDao(e.currentTarget)}
        >
          {t("DAO")}
          <ArrowDropDown sx={{ width: "20px", height: "20px" }} />
        </Box>
        <Menu
          anchorEl={anchDao}
          open={Boolean(anchDao)}
          onClose={daoClose}
          keepMounted
          className={styles.menu}
        >
          <MenuItem className={styles.menuItem} onClick={daoClose}>
            <NavItem text="governance" />
          </MenuItem>
          <MenuItem className={styles.menuItem} onClick={daoClose}>
            <NavItem text="vote" />
          </MenuItem>
          <MenuItem className={styles.menuItem} onClick={daoClose}>
            <NavItem text="leaderboard" />
          </MenuItem>
        </Menu>

        <Box
          className={styles.item}
          onClick={(e) => setAnchMore(e.currentTarget)}
        >
          {t("More")}
          <ArrowDropDown sx={{ width: "20px", height: "20px" }} />
        </Box>
        <Menu
          anchorEl={anchMore}
          open={Boolean(anchMore)}
          onClose={moreClose}
          keepMounted
          className={styles.menu}
        >
          <MenuItem className={styles.menuItem} onClick={moreClose}>
            <NavItem text="community" />
          </MenuItem>
          {/* <MenuItem className={styles.menuItem} onClick={moreClose}>
            <NavItem text="claim" to="/vesting-claim" />
          </MenuItem> */}
          <MenuItem className={styles.menuItem} onClick={moreClose}>
            <ExternalLink href="https://cbridge.celer.network/#/transfer">
              cBridge
              <CallMade sx={{ fontSize: 14, verticalAlign: "middle" }} />
            </ExternalLink>
          </MenuItem>
        </Menu>

        {chainId === ChainId["SHIBUYA"] && <NavItem text="faucet" />}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <SRSPrice />
        <Web3Status />
        {account && <AdvancedOptions />}
        <TopMenuOthers />
        <TopMenuLanguage />
      </Stack>
    </Box>
  )

  return (
    <>
      <AppBar
        elevation={0}
        sx={{ borderRadius: 0, bgcolor: theme.palette.other.heavierColor }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            data-testid="topMenuContainer"
            sx={{ mt: { xs: 1, sm: 3 } }}
          >
            <NavLink
              to="/"
              onClick={() => setShowMobileMenu(false)}
              className={styles.navTitle}
            >
              <IconButton>
                <img src={logoSVG} className={styles.logo} />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                sx={{ display: { xs: "none", sm: "inline" } }}
              >
                Sirius Finance
              </Typography>
              <Typography variant="h6" noWrap sx={{ display: { sm: "none" } }}>
                {activeTab || "Sirius Finance"}
              </Typography>
            </NavLink>

            <Box sx={{ flexGrow: 1, display: { md: "none" } }} />
            {!isDeepLink ? (
              <IconButton
                sx={{ display: { md: "none" }, pr: 0 }}
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                <div
                  className={`${styles.navBtn} ${
                    showMobileMenu ? styles.navBtnActive : ""
                  }`}
                >
                  <i className={styles.navBtnLine}></i>
                </div>
              </IconButton>
            ) : (
              <IconButton sx={{ display: { md: "none" }, pr: 0 }}>
                <ReactLink to={`/${pathname.split("/")[1]}`}>
                  <div className={`${styles.navBtn} ${styles.navBtnActive}`}>
                    <i className={styles.navBtnLine}></i>
                  </div>
                </ReactLink>
              </IconButton>
            )}

            {MobileNavPopup}
            {PcNav}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar sx={{ mt: { xs: 1, sm: 3 } }} />
    </>
  )
}

export default TopMenu
