import {
  ARB_USD_POOL_NAME,
  D4_POOL_NAME,
  FRAX_ARB_USD_POOL_V2_NAME,
  FRAX_OPT_USD_METAPOOL_NAME,
  FTM_USD_POOL_NAME,
  OPT_USD_POOL_NAME,
  POOLS_MAP,
  PoolName,
  PoolTypes,
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_V2_NAME,
  SUSD_METAPOOL_NAME,
  BAI_METAPOOL_NAME,
  SUSD_METAPOOL_V2_NAME,
  USDS_ARB_USD_METAPOOL_NAME,
  WCUSD_METAPOOL_NAME,
  WCUSD_METAPOOL_V2_NAME,
  STARLAY_POOL_NAME,
  JPYC_POOL_NAME,
  WBTC_METAPOOL_NAME,
  WETH_METAPOOL_NAME,
  WBNB_METAPOOL_NAME,
  DOT_METAPOOL_NAME,
  LASTR_METAPOOL_NAME,
  WBTC_WETH_4SRS_METAPOOL_NAME,
  AVAULT_POOL_NAME,
  PUSDT_METAPOOL_NAME,
  Pool,
} from "../constants"
import {
  Container,
  Stack,
  Box,
  Typography,
  Grid,
  Drawer,
  Link,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import { BigNumber } from "ethers"
import CustomTooltip from "../components/CustomTooltip"
import PoolOverview from "../components/PoolOverview"
import { Zero } from "@ethersproject/constants"
import { formatBNToString, commify, formatBNToShortString } from "../utils"
import styles from "./Pools.module.scss"
import { useActiveWeb3React } from "../hooks"
import { useApproveAndMigrate } from "../hooks/useApproveAndMigrate"
import usePoolData from "../hooks/usePoolData"
import MoneyLogo from "../assets/Illustration-Money.png"
import { isMobile } from "react-device-detect"
import CloseIcon from "@mui/icons-material/Close"
import {
  FilterTabContext,
  FilterTab,
  FilterTabItem,
  FilterTabList,
  TabContext,
} from "../components/FilterTabs"
import { ReactComponent as ArrowDownIcon } from "../assets/icons/arrow-down.svg"
import bellIcon from "../assets/icons/bell.svg"

function Pools(): ReactElement | null {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const [d4PoolData, d4UserShareData] = usePoolData(D4_POOL_NAME)
  const [usdPoolV2Data, usdV2UserShareData] = usePoolData(
    STABLECOIN_POOL_V2_NAME,
  )
  const [usdPoolData, usdUserShareData] = usePoolData(STABLECOIN_POOL_NAME)
  const [susdPoolData, susdUserShareData] = usePoolData(SUSD_METAPOOL_NAME)
  const [susdPoolV2Data, susdV2UserShareData] = usePoolData(
    SUSD_METAPOOL_V2_NAME,
  )

  const [baiPoolData, baiUserShareData] = usePoolData(BAI_METAPOOL_NAME)
  const [starlayPoolData, starlayUserShareData] = usePoolData(STARLAY_POOL_NAME)
  const [jpycPoolData, jpycUserShareData] = usePoolData(JPYC_POOL_NAME)

  const [wcusdPoolData, wcusdUserShareData] = usePoolData(WCUSD_METAPOOL_NAME)
  const [wcusdPoolV2Data, wcusdV2UserShareData] = usePoolData(
    WCUSD_METAPOOL_V2_NAME,
  )
  const [arbUsdPoolData, arbUsdUserShareData] = usePoolData(ARB_USD_POOL_NAME)
  const [ftmUsdPoolData, ftmUsdUserShareData] = usePoolData(FTM_USD_POOL_NAME)
  const [optUsdPoolData, optUsdUserShareData] = usePoolData(OPT_USD_POOL_NAME)
  const [fraxOptUsdPoolData, fraxOptUsdUserShareData] = usePoolData(
    FRAX_OPT_USD_METAPOOL_NAME,
  )
  const [fraxArbUsdPoolV2Data, fraxArbUsdV2UserShareData] = usePoolData(
    FRAX_ARB_USD_POOL_V2_NAME,
  )
  const [usdsArbUsdPoolData, usdsArbUsdUserShareData] = usePoolData(
    USDS_ARB_USD_METAPOOL_NAME,
  )
  const [wbtcPoolData, wbtcUserShareData] = usePoolData(WBTC_METAPOOL_NAME)
  const [wethPoolData, wethUserShareData] = usePoolData(WETH_METAPOOL_NAME)
  const [wbnbPoolData, wbnbUserShareData] = usePoolData(WBNB_METAPOOL_NAME)
  const [dotPoolData, dotUserShareData] = usePoolData(DOT_METAPOOL_NAME)
  const [lAstrPoolData, lAstrUserShareData] = usePoolData(LASTR_METAPOOL_NAME)
  const [wBTCWETH4SRSPoolData, wBTCWETH4SRSUserShareData] = usePoolData(
    WBTC_WETH_4SRS_METAPOOL_NAME,
  )
  const [avaultPoolData, avaultUserShareData] = usePoolData(AVAULT_POOL_NAME)
  const [pusdtPoolData, pusdtUserShareData] = usePoolData(PUSDT_METAPOOL_NAME)

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const approveAndMigrate = useApproveAndMigrate()
  const [TVL, setTVL] = useState("-")
  const [isFirstOnPools, setIsFirstOnPools] = useState(false)
  const [activeMigration, setActiveMigration] = useState<{
    poolName: PoolName | null
    lpTokenBalance: BigNumber
    lpTokenName: string
  }>({ poolName: null, lpTokenBalance: Zero, lpTokenName: "" })
  const [filter, setFilter] = useState<PoolTypes | "all" | "outdated">("all")
  const [showArchived, setShowArchived] = useState(false)

  const [sortName, setSortName] = useState("name")
  const [sortOrder, setSortOrder] = useState(1)
  const changeSort = (name: string) => {
    setSortName(name)
    setSortOrder(-sortOrder)
  }
  useEffect(() => {
    setActiveMigration({
      poolName: null,
      lpTokenBalance: Zero,
      lpTokenName: "",
    })
  }, [account, chainId])

  useEffect(() => {
    const reserve = usdPoolData.reserve
      ?.add(susdPoolData.reserve || Zero)
      ?.add(starlayPoolData.reserve || Zero)
      ?.add(baiPoolData.reserve || Zero)
      ?.add(jpycPoolData.reserve || Zero)
      ?.add(wbtcPoolData.reserve || Zero)
      ?.add(wethPoolData.reserve || Zero)
      ?.add(wbnbPoolData.reserve || Zero)
      ?.add(dotPoolData.reserve || Zero)
      ?.add(lAstrPoolData.reserve || Zero)
      ?.add(wBTCWETH4SRSPoolData.reserve || Zero)
      ?.add(avaultPoolData.reserve || Zero)
      ?.add(pusdtPoolData.reserve || Zero)

    if (reserve) {
      const str = formatBNToShortString(reserve, 18)

      setTVL(str)
    }
  }, [
    usdPoolData,
    susdPoolData,
    starlayPoolData,
    baiPoolData,
    jpycPoolData,
    wbtcPoolData,
    wethPoolData,
    wbnbPoolData,
    dotPoolData,
    lAstrPoolData,
    wBTCWETH4SRSPoolData,
    avaultPoolData,
    pusdtPoolData,
  ])

  useEffect(() => {
    const ret = window.localStorage.getItem("isFirstOnPools")
    if (ret) {
      setIsFirstOnPools(false)
    } else {
      setIsFirstOnPools(true)
    }
  }, [])

  function getPropsForPool(poolName: PoolName) {
    if (poolName === D4_POOL_NAME) {
      return {
        name: D4_POOL_NAME,
        poolData: d4PoolData,
        userShareData: d4UserShareData,
        poolRoute: "/pools/d4",
      }
    } else if (poolName === STABLECOIN_POOL_NAME) {
      return {
        name: STABLECOIN_POOL_NAME,
        poolData: usdPoolData,
        userShareData: usdUserShareData,
        poolRoute: "/pools/usd",
      }
    } else if (poolName === STABLECOIN_POOL_V2_NAME) {
      return {
        name: STABLECOIN_POOL_V2_NAME,
        poolData: usdPoolV2Data,
        userShareData: usdV2UserShareData,
        poolRoute: "/pools/usdv2",
      }
    } else if (poolName === SUSD_METAPOOL_NAME) {
      return {
        name: SUSD_METAPOOL_NAME,
        poolData: susdPoolData,
        userShareData: susdUserShareData,
        poolRoute: "/pools/susd",
      }
    } else if (poolName === BAI_METAPOOL_NAME) {
      return {
        name: BAI_METAPOOL_NAME,
        poolData: baiPoolData,
        userShareData: baiUserShareData,
        poolRoute: "/pools/bai",
      }
    } else if (poolName === SUSD_METAPOOL_V2_NAME) {
      return {
        name: SUSD_METAPOOL_V2_NAME,
        poolData: susdPoolV2Data,
        userShareData: susdV2UserShareData,
        poolRoute: "/pools/susdv2",
      }
    } else if (poolName === WCUSD_METAPOOL_NAME) {
      return {
        name: WCUSD_METAPOOL_NAME,
        poolData: wcusdPoolData,
        userShareData: wcusdUserShareData,
        poolRoute: "/pools/wcusd",
      }
    } else if (poolName === WCUSD_METAPOOL_V2_NAME) {
      return {
        name: WCUSD_METAPOOL_V2_NAME,
        poolData: wcusdPoolV2Data,
        userShareData: wcusdV2UserShareData,
        poolRoute: "/pools/wcusdv2",
      }
    } else if (poolName === ARB_USD_POOL_NAME) {
      return {
        name: ARB_USD_POOL_NAME,
        poolData: arbUsdPoolData,
        userShareData: arbUsdUserShareData,
        poolRoute: "/pools/arbusd",
      }
    } else if (poolName === FTM_USD_POOL_NAME) {
      return {
        name: FTM_USD_POOL_NAME,
        poolData: ftmUsdPoolData,
        userShareData: ftmUsdUserShareData,
        poolRoute: "/pools/ftmusd",
      }
    } else if (poolName === OPT_USD_POOL_NAME) {
      return {
        name: OPT_USD_POOL_NAME,
        poolData: optUsdPoolData,
        userShareData: optUsdUserShareData,
        poolRoute: "/pools/optusd",
      }
    } else if (poolName === FRAX_OPT_USD_METAPOOL_NAME) {
      return {
        name: FRAX_OPT_USD_METAPOOL_NAME,
        poolData: fraxOptUsdPoolData,
        userShareData: fraxOptUsdUserShareData,
        poolRoute: "/pools/frax-optusd",
      }
    } else if (poolName === FRAX_ARB_USD_POOL_V2_NAME) {
      return {
        name: FRAX_ARB_USD_POOL_V2_NAME,
        poolData: fraxArbUsdPoolV2Data,
        userShareData: fraxArbUsdV2UserShareData,
        poolRoute: "/pools/frax-arbusdv2",
      }
    } else if (poolName === USDS_ARB_USD_METAPOOL_NAME) {
      return {
        name: USDS_ARB_USD_METAPOOL_NAME,
        poolData: usdsArbUsdPoolData,
        userShareData: usdsArbUsdUserShareData,
        poolRoute: "/pools/usds-arbusd",
      }
    } else if (poolName === STARLAY_POOL_NAME) {
      return {
        name: STARLAY_POOL_NAME,
        poolData: starlayPoolData,
        userShareData: starlayUserShareData,
        poolRoute: "/pools/starly",
      }
    } else if (poolName === JPYC_POOL_NAME) {
      return {
        name: JPYC_POOL_NAME,
        poolData: jpycPoolData,
        userShareData: jpycUserShareData,
        poolRoute: "/pools/jpyc",
      }
    } else if (poolName === WBTC_METAPOOL_NAME) {
      return {
        name: WBTC_METAPOOL_NAME,
        poolData: wbtcPoolData,
        userShareData: wbtcUserShareData,
        poolRoute: "/pools/wbtc",
      }
    } else if (poolName === WBNB_METAPOOL_NAME) {
      return {
        name: WBNB_METAPOOL_NAME,
        poolData: wbnbPoolData,
        userShareData: wbnbUserShareData,
        poolRoute: "/pools/wbnb",
      }
      // } else if (poolName === WETH_METAPOOL_NAME) {
    } else if (poolName === DOT_METAPOOL_NAME) {
      return {
        name: DOT_METAPOOL_NAME,
        poolData: dotPoolData,
        userShareData: dotUserShareData,
        poolRoute: "/pools/dot",
      }
    } else if (poolName === LASTR_METAPOOL_NAME) {
      return {
        name: LASTR_METAPOOL_NAME,
        poolData: lAstrPoolData,
        userShareData: lAstrUserShareData,
        poolRoute: "/pools/nastr",
      }
    } else if (poolName === WBTC_WETH_4SRS_METAPOOL_NAME) {
      return {
        name: WBTC_WETH_4SRS_METAPOOL_NAME,
        poolData: wBTCWETH4SRSPoolData,
        userShareData: wBTCWETH4SRSUserShareData,
        poolRoute: "/pools/wbtc-weth-4srs",
      }
    } else if (poolName === AVAULT_POOL_NAME) {
      return {
        name: AVAULT_POOL_NAME,
        poolData: avaultPoolData,
        userShareData: avaultUserShareData,
        poolRoute: "/pools/avault",
      }
    } else if (poolName === PUSDT_METAPOOL_NAME) {
      return {
        name: PUSDT_METAPOOL_NAME,
        poolData: pusdtPoolData,
        userShareData: pusdtUserShareData,
        poolRoute: "/pools/pusdt",
      }
    } else {
      return {
        name: WETH_METAPOOL_NAME,
        poolData: wethPoolData,
        userShareData: wethUserShareData,
        poolRoute: "/pools/weth",
      }
    }
  }

  const [hotPools, setHotPools] = useState<Pool[]>([])
  const [unsortPools, setUnsortPools] = useState<Pool[]>([])
  const [otherPools, setOtherPools] = useState<Pool[]>([])
  useEffect(() => {
    const _pools = Object.values(POOLS_MAP).filter((i) => {
      const poolProps = getPropsForPool(i.name)
      const _filter =
        filter === "all" ||
        i.type === filter ||
        (filter === "outdated" &&
          (i.isOutdated || poolProps.poolData.isMigrated))
      const _archived = showArchived ? true : !i.isOutdated
      return _filter && _archived
    })
    setHotPools(_pools.filter((i) => i.hot))
    setUnsortPools(_pools.filter((i) => i.unsort))

    const _others = _pools
      .filter(
        (i) => (chainId ? i.addresses[chainId] : false) && !i.hot && !i.unsort,
      )
      .sort((a, b) => {
        // @ts-ignore
        return a?.[sortName] > b?.[sortName] ? sortOrder : -sortOrder
      })
    setOtherPools(_others)
  }, [chainId, filter, showArchived, sortName, sortOrder])

  return (
    <Container
      disableGutters={true}
      maxWidth={false}
      sx={{
        maxWidth: 920,
        marginTop: "40px",
        paddingX: {
          xs: 2,
          sm: 0,
        },
      }}
    >
      <Typography variant="h2" align="left" gutterBottom={true} mb={2}>
        {t("pools")}
      </Typography>
      <Grid container spacing={4} columns={15}>
        <Grid xs={15} sm={6} item>
          <div className={styles.info}>
            <Box>
              <Typography fontSize={14} textAlign="center">
                TVL
              </Typography>
              <Typography textAlign="center" fontSize={32} fontWeight={700}>
                ${TVL}
              </Typography>
            </Box>
            <Box mt={4}>
              <Typography fontSize={14} textAlign="center">
                {t("My Total Deposit")}
              </Typography>
              <Typography textAlign="center" fontSize={32} fontWeight={700}>
                $
                {formatBNToShortString(
                  (usdUserShareData?.usdBalance || Zero)
                    .add(susdUserShareData?.usdBalance || Zero)
                    .add(starlayUserShareData?.usdBalance || Zero)
                    .add(jpycUserShareData?.usdBalance || Zero)
                    .add(baiUserShareData?.usdBalance || Zero)
                    .add(dotUserShareData?.usdBalance || Zero)
                    .add(wbtcUserShareData?.usdBalance || Zero)
                    .add(wethUserShareData?.usdBalance || Zero)
                    .add(wbnbUserShareData?.usdBalance || Zero)
                    .add(lAstrUserShareData?.usdBalance || Zero)
                    .add(avaultUserShareData?.usdBalance || Zero)
                    .add(pusdtUserShareData?.usdBalance || Zero),
                  18,
                )}
              </Typography>
            </Box>
            <Box
              mt={4}
              textAlign="center"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <img src={MoneyLogo} alt="" />
            </Box>
            <Box mt={4} mx={4}>
              <Typography
                fontSize={14}
                textAlign="center"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                {t("poolsPageBrief")}
              </Typography>
            </Box>
          </div>
        </Grid>
        <Grid xs={15} sm={9} item>
          <div className={styles.list}>
            <FilterTabContext>
              <Box paddingBottom={2} paddingX={3} paddingTop={4}>
                <Typography fontSize={14} fontWeight={700}>
                  {t("All Pools")}
                </Typography>
              </Box>
              <FilterTabList paddingX={3}>
                {[
                  ["all", "All"] as const,
                  [PoolTypes.USD, "Stablecoins"] as const,
                  [PoolTypes.Crypto, "Crypto"] as const,
                ].map(([filterKey, text]) => (
                  <FilterTab kind={filterKey} key={filterKey}>
                    {t(text)}
                  </FilterTab>
                ))}
              </FilterTabList>
              <TabContext.Consumer>
                {([value]) =>
                  value === PoolTypes.Crypto && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mb={2}
                      ml={2}
                      mr={2}
                      className={styles.alert}
                    >
                      <img src={bellIcon} />
                      <Typography fontSize={12} ml={1}>
                        <Trans i18nKey="cryptoPoolTooltip">
                          Please be aware that pools with unstable assets may
                          have possible
                          <Link
                            href="https://medium.com/@siriusfinance/understanding-unstable-cryptocurrency-pools-c46732c1520f"
                            target="_blank"
                          >
                            impermanent loss
                          </Link>
                          .
                        </Trans>
                      </Typography>
                    </Box>
                  )
                }
              </TabContext.Consumer>

              <Box
                px={3}
                height={4}
                mb={4}
                display="flex"
                justifyContent="flex-end"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setShowArchived(!showArchived)}
                        checked={showArchived}
                      />
                    }
                    label={
                      <Typography fontSize={12}>
                        {t("Show Archived")}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box>
              <Stack
                direction="row"
                fontSize={12}
                px={3}
                py={2}
                className={styles.thead}
              >
                <Box flex={2}>{t("assets")}</Box>
                <Box
                  flex={2}
                  display={{ xs: "none", sm: "block" }}
                  sx={{
                    whiteSpace: "nowrap",
                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={() => changeSort("name")}
                >
                  {t("name")}
                  <Box
                    display={sortName == "name" ? "inline-block" : "none"}
                    sx={{ transform: sortOrder > 0 ? "rotate(180deg)" : "" }}
                    ml={0.5}
                  >
                    <ArrowDownIcon className={styles.ArrowDownIcon} />
                  </Box>
                </Box>
                <Box
                  flex={1}
                  textAlign="center"
                  display={{ xs: "none", sm: "block" }}
                  sx={{
                    whiteSpace: "nowrap",
                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={() => changeSort("userBalanceUSD")}
                >
                  {t("My Deposit")}
                  <Box
                    display={
                      sortName == "userBalanceUSD" ? "inline-block" : "none"
                    }
                    sx={{ transform: sortOrder > 0 ? "rotate(180deg)" : "" }}
                    ml={0.5}
                  >
                    <ArrowDownIcon className={styles.ArrowDownIcon} />
                  </Box>
                </Box>
                <Box flex={1} textAlign="center">
                  <CustomTooltip info={t("feeRewardTooltip") || ""}>
                    <Typography
                      variant="body1"
                      fontSize={12}
                      sx={{
                        textDecoration: "dashed underline",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      ml={1}
                    >
                      {t("Fee Reward")}
                    </Typography>
                  </CustomTooltip>
                </Box>
                <Box
                  flex={1}
                  textAlign="center"
                  sx={{
                    whiteSpace: "nowrap",
                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={() => changeSort("reserve")}
                >
                  TVL
                  <Box
                    display={sortName == "reserve" ? "inline-block" : "none"}
                    sx={{ transform: sortOrder > 0 ? "rotate(180deg)" : "" }}
                    ml={0.5}
                  >
                    <ArrowDownIcon className={styles.ArrowDownIcon} />
                  </Box>
                </Box>
              </Stack>
              {[...hotPools, ...unsortPools].map((i) => (
                <FilterTabItem kind={i.type} key={i.name}>
                  <PoolOverview {...getPropsForPool(i.name)} hot={i.hot} />
                </FilterTabItem>
              ))}
              {otherPools.map((i) => (
                <FilterTabItem kind={i.type} key={i.name}>
                  <PoolOverview {...getPropsForPool(i.name)} />
                </FilterTabItem>
              ))}
            </FilterTabContext>
          </div>
        </Grid>
      </Grid>
      <Drawer
        anchor="bottom"
        open={isMobile && isFirstOnPools}
        onClose={() => {
          window.localStorage.setItem("isFirstOnPools", "true")
          setIsFirstOnPools(false)
        }}
        elevation={0}
        ModalProps={{
          sx: { zIndex: 1099 },
        }}
        PaperProps={{
          sx: {
            borderBottom: "none",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            bottom: "48px",
          },
        }}
      >
        <div>
          <Box
            mt={4}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography
              fontSize={20}
              fontWeight={700}
              textAlign="center"
              position="relative"
            >
              {t("pools")}
            </Typography>
            <Box position="absolute" right="32px">
              <CloseIcon
                onClick={() => {
                  window.localStorage.setItem("isFirstOnPools", "true")
                  setIsFirstOnPools(false)
                }}
              />
            </Box>
          </Box>
          <Box mt={4} textAlign="center">
            <img src={MoneyLogo} alt="" />
          </Box>
          <Box mt={4} mx={4} mb={8}>
            <Typography fontSize={14} textAlign="center">
              {t("poolsPageBrief")}
            </Typography>
          </Box>
        </div>
      </Drawer>
    </Container>
  )
}

export default Pools
